let common = require('./admin-common.js');
jQuery(document).ready(function () {
  var total = 0;
  var total_quantity = 0;
  if ($(window).width() > 750) {
		var text_padding = ($('.btn-select').width()  -  parseInt($('.btn-select select').css('font-size')) + 6) / 2;
	} else {
		var text_padding = ($('.btn-select').width()  -  parseInt($('.btn-select select').css('font-size')) + 4) / 2;
	}
	$('.btn-select select').css({'padding-left': text_padding});
  // SLIDE
  jQuery('.slide_fs001 ul').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    // fade: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
        }
      }
    ]
  });
  // SCROLL TO DIV
  jQuery(window).scroll(function () {
    if (jQuery(this).scrollTop()) {
      jQuery('.scrolltop').addClass('go_scrolltop');
    }
    else {
      jQuery('.scrolltop').removeClass('go_scrolltop');
    }
  });
  // SCROLL TO DIV
  jQuery('.scrolltop').click(function () {
    jQuery(window).scroll(function () {
      if (jQuery(this).scrollTop()) {
        jQuery('.scrolltop').fadeIn();
      }
      else {
        jQuery('.scrolltop').fadeOut();
      }
    });
    jQuery('html, body').animate({
      scrollTop: jQuery(".header_top").offset().top
    }, 800);
  });

  // CONVGERT POPUP CARD TO SELECT SEARCH
  if ($('.js-select2-school').length) {
    var $eSelect2 = $('.js-select2-school');
    var school = jQuery('#check_school').val();

    if (school.length > 0) {
      $eSelect2.prop('disabled', true);
      $eSelect2.find(`option[value="${$('#school_id').val()}"]`).attr('selected','selected');
      $eSelect2.select2({
        containerCssClass: "v1",
        dropdownCssClass: "v1",
        language: common.noResultMessage });
    }

    if ($eSelect2.val()) {
      $('.box-select2').addClass('selected');
    }

    $eSelect2.select2({
      containerCssClass: "v1",
      dropdownCssClass: "v1",
      language: common.noResultMessage });

    $(document).click(function(){
      if (!$(event.target).is("*[class*='select2-'], #school_name")) {
        $eSelect2.select2('close');
      }
    });

    $(document).on('click', '#school_name', function(){
      $eSelect2.select2('open');
    });

    $(document).on('change', '.js-select2-school', function(){
      $('#school_name').val($(this).find('option:selected').text());
      $('#school_id').val($(this).val());
      $('.box-select2').addClass('selected');
    });
  }

  if (jQuery('#school_errors').length) {
    jQuery('#school_name').css("border", "2px solid #d6130a");
    jQuery('.select2-container--default .select2-selection--single.v1').css("border", "2px solid #d6130a");
  }

  if (jQuery('#order_errors').length) {
    jQuery('#order_set_code').css("border", "2px solid #d6130a");
  }

  jQuery('.method_of_payment_sop .botton_line .textwidget').click(function (e) {
    jQuery(this).toggleClass('active').parent().siblings().find('.textwidget').removeClass('active');
    jQuery(this).toggleClass('rotate_i').parent().siblings().find('.textwidget').removeClass('rotate_i');
    jQuery(this).siblings('.expand').slideToggle().parent().siblings().find('.expand').slideUp();
  });

  jQuery('.purchase_order_sop .purchase_order_hd .purchase_line_sop input[type=radio]').each(function () {
    if ($(this)[0].checked) {
      $(this)[0].parentElement.classList.add('active');
    }
  });
  jQuery('.purchase_order_sop .purchase_order_hd .purchase_line_sop').click(function () {
    jQuery(this).addClass('active').siblings().removeClass('active');
  });
  // MENU MOBILE
  jQuery(".icon_mobile_click").click(function () {
    jQuery(this).fadeOut(300);
    jQuery("#page_wrapper").addClass('page_wrapper_active');
    jQuery("#menu_mobile_full").addClass('menu_show').stop().animate({ right: "0px" }, 260);
    jQuery(".close_menu, .bg_opacity").show();
    jQuery('html').css({ "overflow": "hidden" });
  });
  jQuery(".close_menu").click(function () {
    jQuery(".top_header .icon_mobile_click").fadeIn(300);
    jQuery("#menu_mobile_full").animate({ right: "-600px" }, 260).removeClass('menu_show');
    jQuery("#page_wrapper").removeClass('page_wrapper_active');
    jQuery(this).hide();
    jQuery('.bg_opacity').hide();
    if (jQuery('.middle_header').hasClass('fixed_menu')) {
      jQuery('.middle_header.fixed_menu .icon_mobile_click').show();
    }
    jQuery('html').css({ "overflow": "auto" });
  });
  jQuery('.bg_opacity').click(function () {
    jQuery("#menu_mobile_full").animate({ right: "-600px" }, 260).removeClass('menu_show');
    jQuery("#page_wrapper").removeClass('page_wrapper_active');
    jQuery('.close_menu').hide();
    jQuery(this).hide();
    jQuery('.top_header .icon_mobile_click').fadeIn(300);
    if (jQuery('.middle_header').hasClass('fixed_menu')) {
      jQuery('.middle_header.fixed_menu .icon_mobile_click').show();
    }
    jQuery('html').css({ "overflow": "auto" });
  });
  jQuery("#menu_mobile_full ul li a").click(function () {
    jQuery(".icon_mobile_click").fadeIn(300);
    jQuery("#page_wrapper").removeClass('page_wrapper_active');
  });

  jQuery('.mobile-menu .menu>li:not(:has(ul.sub-menu)) , .mobile-menu .menu>li ul.sub-menu>li:not(:has(ul.sub-menu))').addClass('not-have-child');

  // menu cap 2
  jQuery('.mobile-menu ul.menu').children().has('ul.sub-menu').click(function () {
    jQuery(this).children('ul').slideToggle();
    jQuery(this).siblings().has('ul.sub-menu').find('ul.sub-menu').slideUp();
    jQuery(this).siblings().find('ul.sub-menu>li').has('ul.sub-menu').removeClass('editBefore_mobile');
  }).children('ul').children().click(function (event) { event.stopPropagation(); });

  //menu cap 3
  jQuery('.mobile-menu ul.menu>li>ul.sub-menu').children().has('ul.sub-menu').click(function () {
    jQuery(this).children('ul.sub-menu').slideToggle();
  }).children('ul').children().click(function (event) { event.stopPropagation(); });

  //menu cap 4
  jQuery('.mobile-menu ul.menu>li>ul.sub-menu>li>ul.sub-menu').children().has('ul.sub-menu').click(function () {
    jQuery(this).children('ul.sub-menu').slideToggle();
  }).children('ul').children().click(function (event) { event.stopPropagation(); });

  jQuery('.mobile-menu ul.menu li').has('ul.sub-menu').click(function (event) {
    jQuery(this).toggleClass('editBefore_mobile');
  });
  jQuery('.mobile-menu ul.menu').children().has('ul.sub-menu').addClass('menu-item-has-children');

  // choose product
  $('select').each(function() {
    var id = $(this)[0].id
    var cd_value = $('select#'+ id).parent().parent().parent().find('input[name=cd_value]').val();
    $('select#'+ id).parent().find('.value').text($('select#'+ id).val());
    if ($(this)[0].value !== '0') {
      $('select#'+ id).parent().parent().parent().parent().parent().parent().find('input[type=checkbox]').prop('checked', true);
    }
    if ($(this)[0].value == cd_value) {
      $('select#'+id).parent().parent().find('button[name=add_quantity]').prop('disabled', 'disabled');
      $('select#'+id).parent().parent().find('li:last-child').addClass('active');
    }
  })
  //disable quatity
  $('.botton_line input[type=checkbox]').each(function () {
    var id = $(this)[0].id;
    var cd_value = $('#'+id).parent().find('.price_sop').find('input[name=cd_value]').val();

    if (!$(this)[0].checked) {
      $('#'+id).parent().find('.price_sop').find('select').prop('disabled', 'disabled');
      $('#'+id).parent().find('.price_sop').find('button').prop('disabled', 'disabled');
      $('#'+id).parent().find('.price_sop').find('li').addClass('active');
    } else {
      $('#'+id).parent().toggleClass('active');
      if ($('#'+id).parent().find('.price_sop').find('select').val() == cd_value) {
        $('#'+id).parent().find('.price_sop').find('button[name=add_quantity]').prop('disabled', 'disabled');
        $('#'+id).parent().find('.price_sop').find('li:last-child').addClass('active');

      }
      if ($('#'+id).parent().find('.price_sop').find('select').val() == '0') {
        $('#'+id).parent().find('.price_sop').find('button[name=sub_quantity]').prop('disabled', 'disabled');
        $('#'+id).parent().find('.price_sop').find('li:first-child').addClass('active');
      }
    }
  });
  $('select').change(function (event){
    var quantity = $(event.currentTarget).val();
    var cd_value = $(event.currentTarget).parent().parent().parent().find('input[name=cd_value]').val();

    if (quantity !== '0') {
      $(event.currentTarget).parent().parent().find('button[name=sub_quantity]').prop('disabled', false);
      $(event.currentTarget).parent().parent().find('li:first-child').removeClass('active');
      $(event.currentTarget).parent().parent().find('button[name=add_quantity]').prop('disabled', false);
      $(event.currentTarget).parent().parent().find('li:last-child').removeClass('active');
    }
    if (quantity == cd_value) {
      $(event.currentTarget).parent().parent().find('button[name=add_quantity]').prop('disabled', 'disabled');
      $(event.currentTarget).parent().parent().find('li:last-child').addClass('active');
    }
    if (quantity == '0') {
      $(event.currentTarget).parent().parent().find('button[name=sub_quantity]').prop('disabled', 'disabled');
      $(event.currentTarget).parent().parent().find('li:first-child').addClass('active');
      $(event.currentTarget).parent().parent().find('button[name=add_quantity]').prop('disabled', 'disabled');
      $(event.currentTarget).parent().parent().find('li:last-child').addClass('active');
      $(event.currentTarget).prop('disabled', 'disabled');
      $(event.currentTarget).parent().parent().parent().parent().parent().parent().find('input[type=checkbox]').prop('checked', false);
      $(event.currentTarget).parent().parent().parent().parent().parent().parent().toggleClass('active');
    }
    $(event.currentTarget).parent().find('.value').text($(event.currentTarget).val());
  });
  $('button[name=add_quantity]').click(function (event) {
    var value_select = parseInt($(event.currentTarget).parent().parent().find('select').val());
    var cd_value = parseInt($(event.currentTarget).parent().parent().parent().find('input[name=cd_value]').val());

    if (value_select == 0) {
      $(event.currentTarget).parent().parent().find('button[name=sub_quantity]').prop('disabled', false);
      $(event.currentTarget).parent().parent().find('li:first-child').removeClass('active');
    }
    if (value_select == (cd_value -1)) {
      $(event.currentTarget).parent().parent().find('select').val(value_select+1);
      $(event.currentTarget).parent().parent().find('button[name=add_quantity]').prop('disabled', 'disabled');
      $(event.currentTarget).parent().parent().find('li:last-child').addClass('active');
    } else {
      $(event.currentTarget).parent().parent().find('select').val(value_select+1);
    }
    $(event.currentTarget).parent().parent().find('.value').text($(event.currentTarget).parent().parent().find('select').val());
  });
  $('button[name=sub_quantity]').click(function (event) {
    var value_select = parseInt($(event.currentTarget).parent().parent().find('select').val());
    var cd_value = parseInt($(event.currentTarget).parent().parent().parent().find('input[name=cd_value]').val());

    if (value_select == cd_value) {
      $(event.currentTarget).parent().parent().find('button[name=add_quantity]').prop('disabled', false);
      $(event.currentTarget).parent().parent().find('li:last-child').removeClass('active');
    }
    if (value_select == 1) {
      $(event.currentTarget).parent().parent().find('select').val(value_select-1);
      $(event.currentTarget).parent().parent().find('button[name=sub_quantity]').prop('disabled', 'disabled');
      $(event.currentTarget).parent().parent().find('li:first-child').addClass('active');
      $(event.currentTarget).parent().parent().find('button[name=add_quantity]').prop('disabled', 'disabled');
      $(event.currentTarget).parent().parent().find('li:last-child').addClass('active');
      $(event.currentTarget).parent().parent().find('select').prop('disabled', 'disabled');
      $(event.currentTarget).parent().parent().parent().parent().parent().parent().find('input[type=checkbox]').prop('checked', false);
      $(event.currentTarget).parent().parent().parent().parent().parent().parent().toggleClass('active');
    } else {
      $(event.currentTarget).parent().parent().find('select').val(value_select-1);
    }
    $(event.currentTarget).parent().parent().find('.value').text($(event.currentTarget).parent().parent().find('select').val());
  });
  $('.botton_line select').each(function() {
    var id = $(this)[0].id
    var cd_value = $('select#'+id).parent().parent().parent().find('input[name=cd_value]').val();

    if ($(this)[0].value == cd_value) {
      $('select#'+id).parent().parent().find('button[name=add_quantity]').prop('disabled', 'disabled');
      $('select#'+id).parent().parent().find('li:last-child').addClass('active');
    }
  });
  //submit for link to
  $('#link_to_submit').click(function(){
    $('#btn_choose_product').click();
  });
  //back_to_order
  $('#back_to_order').click(function() {
    $(document).ready(function() {
      $.ajax({
        url: '/products/back_to_order',
        type: 'POST',
        success:function() {}
      })
    })
  })
  $(window).resize(function(){
    if ($(window).width() > 750) {
      var text_padding = ($('.btn-select').width()  -  parseInt($('.btn-select select').css('font-size')) + 6) / 2;
    } else {
      var text_padding = ($('.btn-select').width()  -  parseInt($('.btn-select select').css('font-size')) + 4) / 2;
    }
    $('.btn-select select').css({'padding-left': text_padding});
  });
});
