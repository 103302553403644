exports.noResultMessage = {
  noResults: function(params) {
    return '結果が見つかりません。';
  }
}

exports.allStatus = '9';

exports.allDivisions = '9';

exports.MSG_EXTRACT_CSV = 'ダウンロードする受注データを選択してください';

exports.confirmed = '1';

exports.MSG_A_CSV_0026 = '選択しているデータに確定区分が確定済のものが入っているため、ダウンロードできません。もう一度やり直してください。';

exports.allTypes = '9';

exports.MSG_A_DATA_001 = '年度を選択してください。';

exports.MSG_A_CSV_0025 = '入金年月を指定してください。';

exports.Confirmed = '確定済';

exports.valid = '0';

exports.invalid = '1';

exports.MSG_A_AC_0021 = 'このアカウントを無効にします。無効したらログインできなくなります。よろしいでしょうか？';

exports.MSG_A_AC_0022 = 'このアカウントを有効にし、入力した内容で更新します。よろしいでしょうか。';

exports.MSG_A_CSV_0034 = '届け先番号は3桁の英数字で入力してください。';

exports.MSG_INVOICE_ERROR = 'ダウンロードできません、もう一度お試しください。';

exports.replaceItemLast = /.$/;

exports.AddressCodeRegex = function(str) {
  return /^([0-9]|[０-９]){3}$/.test(str);
}

exports.AddClassErrorAddressCode = function() {
  $('#address_code').addClass('input-error');
  $('.filed-errors-export').text(exports.MSG_A_CSV_0034);
};

exports.RemoveClassErrorAddressCode = function() {
  $('#address_code').removeClass('input-error');
  $('.filed-errors-export').text('');
};
