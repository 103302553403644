// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import $ from 'jquery';
global.$ = $;
global.jQuery = $;

import "../stylesheets/application";
import "./lib/wow.min.js";
import "./lib/jquery.min.js";
import "./lib/bootstrap.min.js";
import "./lib/slick.js";
import "select2/dist/js/select2.full.min.js";
import "./custom.js";
import "./front/customer.js";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
