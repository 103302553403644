$(document).ready(function () {
  if ($('#grade_error').length) {
    $('#grade').css("border", "2px solid #d6130a");
  }

  if ($('#attendance_number_error').length) {
    $('#attendance_number').css("border", "2px solid #d6130a");
  }

  if ($('#class_name_error').length) {
    $('#class_name').css("border", "2px solid #d6130a");
  }

  if ($('#children_name_error').length) {
    $('#children_name').css("border", "2px solid #d6130a");
  }

  if ($('#phone_number_error').length) {
    $('#phone_number').css("border", "2px solid #d6130a");
  }

  if ($('#email_error').length) {
    $('#email').css("border", "2px solid #d6130a");
  }
})
